import React from "react";
import styles from "./ProjectNav.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { NavLink } from "react-router-dom";

const ProjectNav = (props) => {
  const {
    projectsActive,
    projects,
    setActiveProject,
    toggleProjects,
  } = props;

  return (
    <AnimatePresence>
      {projectsActive && (
        <motion.div
          className={styles.main}
          initial={{ x: "100%" }}
          animate={{ x: "0%" }}
          exit={{ x: "100%" }}
          transition={{ duration: 0.5, type: "tween" }}
        >
          {projects.map((project, index) => (
            <NavLink
            to={`/${project.slug}`}
              className={styles.project}
              key={index}
              onMouseEnter={() => {
                setActiveProject(index);
              }}
              onMouseLeave={() => {
                
                  setActiveProject(null);
                
              }}
              onClick={toggleProjects}
            >
              <p>{project.title}</p>
              <p style={{fontStyle: "italic"}}>{project.tags}</p>
            </NavLink>
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ProjectNav;
