import React from 'react';
import styles from './ProjectText.module.scss'
import BlockContent from "@sanity/block-content-to-react";

const ProjectText = (props) => {
    const { object } = props;

    return (
        <div className={styles.main}>
            <BlockContent blocks={object.text} />
        </div>
    );
};

export default ProjectText;