import React, { Fragment } from 'react'
import styles from './ProjectCovers.module.scss';
import { AnimatePresence } from "framer-motion";
import classnames from 'classnames/bind'

const ProjectCovers = (props) => {
    const { projects, activeProject, width, height } = props;
    const c = classnames.bind(styles);

    return (
        <Fragment>
            {
                projects.map((project, index) => (
                    <AnimatePresence key={index}>
                        {
                            index === activeProject && (
                                <div className={c({main: true}, {portrait: height > width})} style={{backgroundImage: `url(${project.cover}), url(${project.covermd}), url(${project.coversm}), url(${project.covercol})`}}/>
                            )
                        }
                    </AnimatePresence>
                ))
            }
        </Fragment>
    );
};

export default ProjectCovers;