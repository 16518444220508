import React, { useState, useRef, Fragment } from 'react';
import styles from './ProjectImage.module.scss';
import { AnimatePresence } from "framer-motion";
import classnames from 'classnames/bind'

const ProjectImage = (props) => {
    const { object } = props;
    const c = classnames.bind(styles);
    const [hoverActive, setHoverActive] = useState(false);
    const [mainLoaded, setMainLoaded] = useState(false);
    const [hoverLoaded, setHoverLoaded] = useState(false);
    const image = useRef();
    const container = useRef();

    const setHeight = () => {
        console.log(container);
        container.current.style.paddingBottom = `${100*(image.current.naturalHeight / image.current.naturalWidth)}%`;
    }

    return (
        <div className={c(
            {main: true},
            {square: object.square},
            {frame: object.frame}
        )}> 
            <div ref={container} style={{width: "100%", position: "relative", paddingBottom: "59%", overflow: "hidden"}}>
                <img className={styles.mainImage} ref={image} src={object.imagesm} alt="" style={{pointerEvents: "none", opacity: mainLoaded ? "0" : "1"}} onLoad={setHeight}/>
                <img className={styles.mainImage} src={object.image} alt="" onMouseEnter={() => {setHoverActive(true)}} onMouseLeave={() => {setHoverActive(false)}} onLoad={() => {setMainLoaded(true)}}/>
                <AnimatePresence>
                {
                    object.altImageActive && hoverActive && object.hoverImage && (
                        <Fragment>
                            <img className={styles.mainImage} src={object.hoverImagesm} alt="" style={{pointerEvents: "none", opacity: hoverLoaded ? "0" : "1"}}/>
                            <img className={styles.mainImage} src={object.hoverImage} alt="" style={{pointerEvents: "none"}} onLoad={() => {setHoverLoaded(true)}}/>
                        </Fragment>
                    )
                }
            </AnimatePresence>
            </div>
            
            
            {
                object.frame && object.line1 ?
                <p>{object.line1}</p>
                : ''
            }
            {
                object.frame && object.line2 ?
                <p>{object.line2}</p>
                : ''
            }
        </div>
    );
};

export default ProjectImage;