import React, {Fragment} from 'react';
import styles from './Frame.module.scss'

const Frame = (props) => {

    return (
        <Fragment>
            <div className={styles.top}></div>
            <div className={styles.left}></div>
            <div className={styles.right}></div>
            <div className={styles.bottom}></div>
        </Fragment>
    );
};

export default Frame;