import React, {
  useEffect,
  useState,
  useCallback,
  Fragment
} from "react";
import "./styles/globals.scss";
import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "./client";
import Helmet from "react-helmet"

import MainNav from "./components/UI/MainNav";
import Frame from "./components/UI/Frame";
import About from "./components/Content/About";
import ProjectNav from "./components/UI/ProjectNav";
import ProjectCovers from "./components/Content/ProjectCovers"
import Project from "./components/Content/Project"
import Landing from "./components/Content/Landing";

const builder = imageUrlBuilder(sanityClient);

const urlFor = (source) => {
  return builder.image(source);
};

function App(props) {
  const slug = props.match.params.slug;
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [aboutActive, setAboutActive] = useState(false);
  const [projectsActive, setProjectsActive] = useState(false);
  const [siteData, setSiteData] = useState(false);
  const [activeProject, setActiveProject] = useState(null);


  const toggleAbout = () => {
    if (aboutActive) {
      setAboutActive(false)
    } else {
      setAboutActive(true)
      setProjectsActive(false)
    }
  }

  const toggleProjects = () => {
    if (projectsActive) {
      setProjectsActive(false)
    } else {
      setProjectsActive(true)
      setAboutActive(false)
    }
  }


  const fetchSiteData = useCallback(async () => {
    const query = 
    `*[_id == "home"][0] {
      title,
      about,
      interaction,
      links,
      color,
      meta,
      "projects" : *[_type == "project"] | order(date desc) {
        title,
        tags,
        "slug" : slug.current,
        cover,
        content
      }
    }`;

    sanityClient
      .fetch(query)
      .then((data) => {
        console.log(data);
        let tempData = {
          title: data.title,
          about: data.about,
          links: data.links,
          color: data.color.hex,
          meta: data.meta, 
          frames: [],
          projects: data.projects,
          landingPlaceholder: urlFor(data.interaction.frames[0]).width(Math.floor((windowDimensions.width - (windowDimensions.width < 769 ? 30 : 80) * 0.2)) ).height(Math.floor((windowDimensions.height - (windowDimensions.width < 769 ? 88 : 150) * 0.2))).url()
        }
        const cropData = data.interaction.frames[0].crop;
        const hotspotData = data.interaction.frames[0].hotspot;
        for(let i = 0; i < data.interaction.frames.length; i++) {
          const asset = {
            _key: data.interaction.frames[i]._key,
            _type: "image",
            asset: data.interaction.frames[i].asset,
            crop: cropData,
            hotspot: hotspotData
          }
          tempData.frames[i] = urlFor(asset).width(windowDimensions.width - (windowDimensions.width < 769 ? 30 : 80)).height(windowDimensions.height - (windowDimensions.width < 769 ? 88 : 150)).url()
        }
        
        for(let i = 0; i < data.projects.length; i++) {
          tempData.projects[i].covercol = urlFor(data.projects[i].cover).width(1).height(1).url();
          tempData.projects[i].coversm = urlFor(data.projects[i].cover).width(Math.floor((windowDimensions.width - (windowDimensions.width < 769 ? 30 : 80)) * 0.1)).height(Math.floor((windowDimensions.height - (windowDimensions.width < 769 ? 88 : 150)) * 0.1) ).blur(20).format("webp").url();
          tempData.projects[i].covermd = urlFor(data.projects[i].cover).width(Math.floor((windowDimensions.width - (windowDimensions.width < 769 ? 30 : 80)) * 0.5)).height(Math.floor((windowDimensions.height - (windowDimensions.width < 769 ? 88 : 150)) * 0.5) ).blur(5).format("webp").url();
          tempData.projects[i].cover = urlFor(data.projects[i].cover).width(windowDimensions.width - (windowDimensions.width < 769 ? 30 : 80)).height(windowDimensions.height - (windowDimensions.width < 769 ? 88 : 150)).format("webp").url();
          

          tempData.projects[i].content.map((object) => {
            if (object._type === "projectImage") {
              if (!object.square) { 
                object.imagesm = urlFor(object.image).width(Math.ceil((windowDimensions.width * 0.1))).format("webp").blur(20).url()
                object.image = urlFor(object.image).width(Math.ceil(windowDimensions.width * 0.8)).format("webp").url()
              } else {
                if (windowDimensions.width < 769) {
                  object.imagesm = urlFor(object.image).width(Math.ceil(windowDimensions.width * 0.1)).height(Math.ceil(windowDimensions.width * 0.1)).format("webp").blur(20).url()
                  object.image = urlFor(object.image).width(Math.ceil(windowDimensions.width * 0.8)).height(Math.ceil(windowDimensions.width * 0.8)).format("webp").url()
                } else {
                  object.imagesm = urlFor(object.image).width(Math.ceil(windowDimensions.width * 0.01)).height(Math.ceil(windowDimensions.width * 0.01)).format("webp").blur(20).url()
                  object.image = urlFor(object.image).width(Math.ceil(windowDimensions.width * 0.3)).height(Math.ceil(windowDimensions.width * 0.3)).format("webp").url()
                }
              }

              if (object.altImageActive && object.hoverImage) {
                if (!object.square) { 
                  object.hoverImagesm = urlFor(object.hoverImage).width(Math.ceil(windowDimensions.width * 0.1)).format("webp").blur(20).url()
                  object.hoverImage = urlFor(object.hoverImage).width(Math.ceil(windowDimensions.width * 0.8)).format("webp").url()
                } else {
                  if (windowDimensions.width < 769) {  
                    object.hoverImagesm = urlFor(object.hoverImage).width(Math.ceil(windowDimensions.width * 0.1)).height(Math.ceil(windowDimensions.width * 0.1)).format("webp").blur(20).url()
                    object.hoverImage = urlFor(object.hoverImage).width(Math.ceil(windowDimensions.width * 0.8)).height(Math.ceil(windowDimensions.width * 0.8)).format("webp").url()
                  } else {
                    object.hoverImagesm = urlFor(object.hoverImage).width(Math.ceil(windowDimensions.width * 0.1)).height(Math.ceil(windowDimensions.width * 0.1)).format("webp").url()
                    object.hoverImage = urlFor(object.hoverImage).width(Math.ceil(windowDimensions.width * 0.3)).height(Math.ceil(windowDimensions.width * 0.3)).format("webp").url()
                  }
                }
              }
            }
            return null;
          }) 
        }

        setSiteData(tempData);
      })
      .catch(console.error);
  }, [setSiteData, windowDimensions.height, windowDimensions.width]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    if (!siteData) {
      fetchSiteData();
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [siteData, fetchSiteData]);

  if (!siteData) { return null }

  if (slug && activeProject === null) {
    siteData.projects.map((project, index) => {
      if(project.slug === slug) { setActiveProject(index) }
      return null;
    })
    
  }

  console.log(siteData)

  return (
    <Fragment>
      <Helmet>
        <title>{`ASH.WORK  ${slug ? "| " + siteData.projects[activeProject]?.title : ''}`}</title>
        <meta name="title" content={`ASH.WORK  ${slug ? "| " + siteData.projects[activeProject]?.title : ''}`} />
        <meta name="description" content={siteData.meta?.description}/>

        <meta property="og:type" content="website"/>
        <meta property="og:url" content={`https://ash.work${slug ? `/${slug}` : ''}`}/>
        <meta property="og:title" content={`ASH.WORK  ${slug ? "| " + siteData.projects[activeProject]?.title : ''}`}/>
        <meta property="og:description" content={siteData.meta?.description}/>
        <meta property="og:image" content={ slug && activeProject ? siteData.projects[activeProject].cover : urlFor(siteData.meta?.socialImage).width(1200).height(630).url()}/>

        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content={`https://ash.work${slug ? `/${slug}` : ''}`}/>
        <meta property="twitter:title" content={`ASH.WORK  ${slug ? "| " + siteData.projects[activeProject]?.title : ''}`}/>
        <meta property="twitter:description" content={siteData.meta?.description}/>
        <meta property="twitter:image" content={ slug && activeProject ? siteData.projects[activeProject].cover : urlFor(siteData.meta?.socialImage).width(1200).height(700).url()}/>
      </Helmet>
      <MainNav 
        title={siteData.title}
        links={siteData.links}
        toggleAbout={toggleAbout}
        toggleProjects={toggleProjects}
        setActiveProject={setActiveProject}
        projects={siteData.projects}
        activeProject={activeProject}
        width={windowDimensions.width}
          height={windowDimensions.height}
        setProjectsActive={setProjectsActive}
        setAboutActive={setAboutActive}
      /> 
      <div style={{
        position: "fixed",
        overflow: "hidden",
        width: windowDimensions.width, 
        height: windowDimensions.height, 
        top: "0", 
        left: "0",
      }}>  
        <Frame />  
        <Landing 
          frames={siteData.frames}
          width={windowDimensions.width}
          height={windowDimensions.height}
          placeholder={siteData.landingPlaceholder}
          color={siteData.color}
        />
        <About 
          aboutActive={aboutActive} 
          about={siteData.about}
        />
        <ProjectNav 
          projectsActive={projectsActive}
          projects={siteData.projects}
          setActiveProject={setActiveProject}
          toggleProjects={toggleProjects}
          activeProject={activeProject}
        />
        <ProjectCovers
          activeProject={activeProject}
          projects={siteData.projects}
          width={windowDimensions.width}
          height={windowDimensions.height}
        />
      </div>
      <Project
        projectsActive={projectsActive}
        projects={siteData.projects}
        aboutActive={aboutActive} 
        slug={slug}
      />
      
      {/* <img src={siteData.frames[0]} style={{height: "100vh", marginLeft: "40px", marginTop:"75px", position: "fixed", top: "0", lef: "0"}} alt="landingTemp"/> */}
    </Fragment>
  );
}

export default App;
