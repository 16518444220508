import React from 'react';
import styles from './About.module.scss'
import { motion, AnimatePresence } from "framer-motion";
import BlockContent from "@sanity/block-content-to-react";

const About = (props) => {
    const { aboutActive, about } = props;
    
    return (
        <AnimatePresence>
            {
                aboutActive && (
                    <motion.div
                        className={styles.main}
                        initial={{x: "-100%"}}
                        animate={{ x: "0%" }}
                        exit={{ x: "-100%" }}
                        transition={{ duration: 0.5, type: "tween" }}
                    >
                        <BlockContent className={styles.copy} blocks={about} />
                    </motion.div>
                )
            }
            
        </AnimatePresence>
    );
};

export default About;