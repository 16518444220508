import React, { useEffect, useRef } from "react";
import styles from "./Landing.module.scss";

const scale = 1;
const offsetX = 0.5;
const offsetY = 0.5;

const Landing = (props) => {
  const { frames, width, height, placeholder, color } = props;
  const canvasRef = useRef();

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    let mouseY = 0;
    let images = [];
    let loaded = 0;
    const interval = context.canvas.height / frames.length;

    const draw = () => {
      
      const currentImage = Math.floor(mouseY / interval);

      if (images[currentImage]){
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        const imageRatio =
            images[currentImage].width / images[currentImage].height;
        const canvasRatio = context.canvas.width / context.canvas.height;
        let displayWidth = context.canvas.width * scale;
        let displayHeight = context.canvas.height * scale;

        if (imageRatio < canvasRatio) {
            displayHeight = displayWidth / imageRatio;
        } else {
            displayWidth = displayHeight * imageRatio;
        }

        context.drawImage(
            images[currentImage],
            (context.canvas.width - displayWidth) * offsetX,
            (context.canvas.height - displayHeight) * offsetY,
            displayWidth,
            displayHeight
        );
        }
    };

    const getMouse = (mousePosition) => {
      mouseY = mousePosition.layerY;
      if (images.length === frames.length && !loaded) {
        for (let i = 0; i < images.length; i++) {
          if (images[i].loaded) {
            loaded = true;
          }
        }
      }
      if (loaded === frames.length) {
        window.requestAnimationFrame(draw);
      }
    };

    const getTouch = (touchPosition) => {

      mouseY = touchPosition.targetTouches[0]?.pageY;
      if (images.length === frames.length && !loaded) {
        for (let i = 0; i < images.length; i++) {
          if (images[i].loaded) {
            loaded = true;
          }
        }
      }
      if (loaded === frames.length) {
        window.requestAnimationFrame(draw);
      }
    };

    canvas.addEventListener("mousemove", getMouse, false);
    canvas.addEventListener("touchmove", getTouch, false);

    const loadIn = () => {
      loaded = 0;
      context.fillStyle = color;
      context.fillRect(0, 0, context.canvas.width, context.canvas.height);
      const placeholderImage = new Image();
      placeholderImage.src = placeholder;
      placeholderImage.width = context.canvas.width;
      placeholderImage.height = context.canvas.height;
      placeholderImage.onload = () => {
        context.filter = "blur(20px)";
        context.drawImage(
          placeholderImage,
          0,
          0,
          placeholderImage.width,
          placeholderImage.height
        );
        context.filter = "blur(0px)";
        context.fillStyle = "#131313";
        context.fillRect(
          context.canvas.width / 2 - (context.canvas.width * 0.3) / 2,
          context.canvas.height / 2 - 40,
          context.canvas.width * 0.3,
          80
        );

        const loadY = context.canvas.height / 2 - 30;
        const loadHeight = 60;
        const loadXOrigin =
          context.canvas.width / 2 - (context.canvas.width * 0.3) / 2 + 10;
        const loadWidth = (context.canvas.width * 0.3 - 20) / frames.length;

        images = frames.map((imageURL, index) => {
          const image = new Image();
          image.src = imageURL;

          image.onload = () => {
            loaded = loaded + 1;
            context.filter = "blur(0px)";
            context.fillStyle = "#F2F2F2";
            context.fillRect(
              loadXOrigin + index * loadWidth,
              loadY,
              loadWidth / 2,
              loadHeight
            );
            // console.log(loaded);
            if (loaded === frames.length) {
              draw();
            }
          };
          return image;
        });

        // console.log(images);
      };
    };

    loadIn();

    window.addEventListener("resize", loadIn, false);
    window.addEventListener("load", loadIn, false);

    return () => {
      canvas.removeEventListener("mousemove", getMouse, false);
      canvas.removeEventListener("touchmove", getTouch, false);
      window.removeEventListener("resize", loadIn, false);
      window.addEventListener("load", loadIn, false);
      window.cancelAnimationFrame(draw);
    };
  });

  return (
    <div className={styles.main} style={{ backgroundColor: color }}>
      <canvas
        ref={canvasRef}
        width={Math.floor((width - (width < 769 ? 30 : 80)) * 1.5)}
        height={Math.floor((height - (width < 769 ? 88 : 150)) * 1.5)}
      ></canvas>
    </div>
  );
};

export default Landing;
