import React, { Fragment } from 'react';
import styles from './Project.module.scss'
import { motion, AnimatePresence } from "framer-motion";

import ProjectImage from "./ProjectImage"
import ProjectText from "./ProjectText"
import ProjectVideo from './ProjectVideo';

const Project = (props) => {
    const { projects, slug, aboutActive, projectsActive } = props;


    return (
        <Fragment>
            {
                projects.map((project, index) => (
                    <AnimatePresence key={index}>
                        {
                            slug === project.slug && !aboutActive && !projectsActive && (
                                <motion.div
                                    initial={{y: "100%"}}
                                    animate={{ y: "0px"}}
                                    exit={{ y: "100%"}}
                                    transition={{ duration: 0.6, type: "tween", delay: 0.4 }}
                                    className={styles.main}
                                >
                                    {
                                        project.content.map((object) => {
                                            if (object._type === "projectImage") {
                                                return (
                                                    <ProjectImage key={object._key} object={object}/>
                                                )
                                            } else if (object._type === "projectText") {
                                                return (
                                                    <ProjectText key={object._key} object={object}/>
                                                )
                                            } else if (object._type === "projectVideo") {
                                                return(
                                                    <ProjectVideo key={object._key} object={object}/>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })
                                    }
                                </motion.div>
                            )
                        }
                    </AnimatePresence>
                ))
            }
        </Fragment>
    );
};

export default Project;