import React from "react";
import styles from "./ProjectVideo.module.scss";
import classnames from "classnames/bind";

const ProjectVideo = (props) => {
  const { object } = props;
  const c = classnames.bind(styles);

  return (
    <div className={c({ main: true }, { frame: object.frame })}>
      <div className={styles.video}>
        <iframe
          src={`https://player.vimeo.com/video/${object.id}?h=1102e0b4c8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          title={object._key}
        ></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      {object.frame && object.line1 ? <p>{object.line1}</p> : ""}
      {object.frame && object.line2 ? <p>{object.line2}</p> : ""}
    </div>
  );
};

export default ProjectVideo;


