import React from "react";
import styles from "./MainNav.module.scss";
import { NavLink } from "react-router-dom";

const MainNav = (props) => {
  const {
    title,
    links,
    toggleAbout,
    toggleProjects,
    setActiveProject,
    projects,
    activeProject,
    setProjectsActive,
    setAboutActive,
    width,
    height,
  } = props;

  return (
    <div
      style={{
        position: "fixed",
        overflow: "hidden",
        width: width,
        height: height,
        top: "0",
        left: "0",
        zIndex: 100,
        pointerEvents: "none",
        mixBlendMode: "difference"
      }}
    >
      <div className={styles.top}>
        <p className={styles.button} onClick={toggleAbout}>
          ABOUT
        </p>
        <NavLink
          className={styles.button}
          style={{ textAlign: "center" }}
          to="/"
          onClick={() => {
            setActiveProject(null);
            setProjectsActive(false);
            setAboutActive(false);
          }}
        >
          {title}
        </NavLink>
        <p
          className={styles.button}
          style={{ textAlign: "right" }}
          onClick={toggleProjects}
        >
          PROJECTS
        </p>
      </div>
      <div className={styles.bottom}>
        <a
          className={styles.button}
          target="_blank"
          rel="noreferrer"
          href={`mailto:${links.email}`}
        >
          EMAIL
        </a>
        <p
          className={styles.button}
          style={{
            width: "50vw",
            textAlign: "center",
            paddingRight: "5px",
            paddingLeft: "5px",
          }}
        >
          {activeProject !== null ? projects[activeProject].title : ""}
        </p>
        <a
          className={styles.button}
          style={{ textAlign: "right" }}
          target="_blank"
          rel="noreferrer"
          href={links.instagram}
        >
          INSTAGRAM
        </a>
      </div>
    </div>
  );
};

export default MainNav;
